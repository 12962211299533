.post-details .card-action{
    background-color: rgb(252, 252, 252);
}
.post-details .card-action .date{
    padding-top: 20px;
    border-top: 2px solid gray;
}
.post-details .card-action a{
    text-transform: initial !important;
    margin: 0px !important;
}
.post-details .card-action p{
    margin-top: 0px;
}