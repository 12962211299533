html, body{
  height: 100%;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
form{
  margin-top: 20px;
  padding: 0px 20px 20px 20px;
}
form button, form h5{
  margin: 20px 0px;
}
/* THIS BELOW MAKES THE FOOTER PLAY NICE :) */
.Site {
  display: flex;
  flex-direction: column;
  height: 100%; /* 1, 3 */
}

.Site-header,
.Site-footer {
  flex: none; /* 2 */
}

.Site-content {
  flex: 1 0 auto; /* 2 */
  padding: var(--space) var(--space) 0;
  width: 100%;
}
.Site-content::after {
  content: '\00a0'; /* &nbsp; */
  display: block;
  margin-top: var(--space);
  height: 0px;
  visibility: hidden;
}
@media (--break-lg) {
  .Site-content {
    padding-top: var(--space-lg);
  }
  .Site-content::after {
    margin-top: var(--space-lg);
  }
}

.Site-content--full {
  padding: 0;
}
.Site-content--full::after {
  content: none;
}