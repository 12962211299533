.about-list li{
    list-style-type: disc !important;
    font-size: 18px;
    margin-left: 20px;
}
ul.about-list{
    font-size: 30px;
    margin-left: 30px;
}
.about-page{
    font-size: 20px
}
.about-page p{
    margin: 0px 0px 30px;
    text-align: justify;
}
.img-2{
    max-width: 100%;
    height: auto;
    margin-top: 20px;
}
.about-page h4{
    margin: 60px 0px;
}